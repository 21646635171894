export default {
  name: 'Input',
  props: {
    vmodel: [String, Number],
    value: [String, Number],
    placeholder: String,
    type: String,
    error: String,
    clearable: Boolean,
    isPasswordInput: Boolean,
    autofocus: String,
    autocomplete: {
      type: String,
      default: 'off'
    },
    maxLength: {
      type: Number,
      default: 524288
    }
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  data () {
    return {
      currentValue: '',
      currentType: 'text',
      css: {
        container: ['dv-input-container']
      }
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    value (val) {
      this.currentValue = val
    },
    currentValue (next) {
      if (this.maxLength > 0) {
        next = next.substr(0, this.maxLength)
      }
      // 样式
      const index = this.css.container.findIndex(e => e === 'full-input')
      if (next.length > 0) {
        index < 0 && this.css.container.push('full-input')
      } else {
        this.css.container.slice(index, 1)
      }
      this.$emit('sync', next)
    },
    error (next) {
      // console.log('error next', next)
      const index = this.css.container.findIndex(e => e === 'error')
      // console.log('index', index)
      if (next) {
        // console.log('should add error class')
        index < 0 && this.css.container.push('error')
      } else {
        // console.log('should remove error class')
        this.css.container.splice(index, 1)
      }
    }
  },
  methods: {
    init () {
      this.currentValue = this.value
      this.currentType = this.type
    },
    handleInputChange (event) {
      if (this.currentType === 'tel') {
        // eslint-disable-next-line
        event.target.value = event.target.value.replace(/[^\d|\+]/g, '')
      } else if (this.isPasswordInput) {
        event.target.value = event.target.value.trim()
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g, '')
      }
      if (!event) {
        this.currentValue = null
      } else {
        this.currentValue = event.target.value
      }
    },
    handleClearInput () {
      this.currentValue = ''
    }
  }
}
