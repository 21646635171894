export default {
  name: 'TextArea',
  props: {
    vmodel: [String, Number],
    value: [String, Number],
    rows: {
      type: Number,
      default: 5
    },
    max: Number,
    placeholder: String,
    type: String,
    error: String
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  data () {
    return {
      currentValue: '',
      css: {
        container: ['dv-input-container']
      }
    }
  },
  watch: {
    value (val) {
      if (val !== this.currentValue) {
        this.currentValue = val
      }
    },
    currentValue (next) {
      const index = this.css.container.findIndex(e => e === 'full-input')
      if (next.length > 0) {
        index < 0 && this.css.container.push('full-input')
      } else {
        this.css.container.slice(index, 1)
      }
      // 判断是否超限
      const errorIndex = this.css.container.findIndex(e => e === 'error')
      if (next.length > this.max) {
        errorIndex < 0 && this.css.container.push('error')
      } else {
        this.css.container.slice(errorIndex, 1)
      }
      console.log(errorIndex, this.css.container)
    },
    error (next) {
      // console.log('error next', next)
      const index = this.css.container.findIndex(e => e === 'error')
      // console.log('index', index)
      if (next) {
        // console.log('should add error class')
        index < 0 && this.css.container.push('error')
      } else {
        // console.log('should remove error class')
        this.css.container.splice(index, 1)
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.currentValue = this.value
    },
    handleInputChange (event) {
      if (!event) {
        this.currentValue = null
      } else {
        this.currentValue = event.target.value
      }
      this.$emit('sync', this.currentValue)
    }
  }
}
