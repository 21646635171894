<template>
  <div
    v-show="isShow"
    id="tip-bg"
    @touchMove="banScroll"
    catchMove
    :style="`background-color: ${bg ? 'rgba(0, 0, 0, 0.6)' : ''}`"
  >
    <div
      class="tip-container"
      :style="`boxShadow: ${
        shadow ? '0px 1px 10px rgba(0, 0, 0, 0.1)' : ''
      }; width: ${isLongText ? '175px' : '150px'}`"
    >
      <img :src="iconList[type] || infoImg" class="img" mode="aspectFit" />
      <div
        class="content-container"
        :style="`margin-bottom: ${type === 'loading' ? '10px' : '20px'}`"
      >
        <span class="content">{{ title }}</span>
        <span v-if="content" class="content">{{ content }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import loadingImg from "./images/loading-icon.svg";
import errorImg from "./images/error-icon.png";
import successImg from "./images/success-icon.png";
import infoImg from "./images/info-icon.png";
import warnImg from "./images/warn-icon.png";

export default {
  name: "Toast",
  props: {
    shadow: Boolean,
    type: {
      type: String,
      default: "",
    },
    bg: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      infoImg,
      iconList: {
        success: successImg,
        error: errorImg,
        loading: loadingImg,
        info: infoImg,
        warn: warnImg,
      },
    };
  },
  computed: {
    isLongText() {
      return this.title.length >= 7 || this.content.length >= 7;
    },
  },
  methods: {
    banScroll(e) {
      e.preventDefault();
    },
  },
};
</script>
<style lang="less" scoped>
.context {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .tip {
    background-color: rgba(40, 40, 40, 0.8);
    color: aliceblue;
    font-size: 0.6rem;
    padding: 0.2rem;
    border-radius: 0.1rem;
  }
}
#tip-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;

  .tip-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    padding: 20px 15px 0 15px;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-flow: column;
    align-items: center;

    .img {
      display: block;
      width: 50px;
      height: 50px;
    }

    .content-container {
      margin-top: 15px;
      margin-bottom: 20px;
      text-align: center;
      line-height: 24px;

      .content {
        display: block;
      }
    }
  }
}
</style>
