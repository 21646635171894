export default {
  name: 'Pagination',
  props: {
    pageSize: {
      type: Number,
      default: 12
    },
    total: {
      type: Number,
      default: 0
    },
    current: {
      type: Number,
      default: 1
    },
    goToPageText: {
      type: String,
      default: 'Go to page'
    },
    showQuickJumper: Boolean
  },
  data () {
    return {
      totalPages: 0,
      pageList: [],
      jumpNextPage: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 计算总页数
      const buffer = this.total / this.pageSize
      const totalPages = buffer > parseInt(buffer) ? parseInt(buffer) + 1 : buffer
      this.totalPages = totalPages
      // 设计显示的页码
      const current = this.current
      const pageList = []
      const devider = {
        showNumber: '...',
        type: 'devider',
        current: false
      }
      if (totalPages <= 7) {
        // 不出现 ... 的情况
        for (let i = 0; i < totalPages; i += 1) {
          const currentNumber = i + 1
          const obj = {
            showNumber: currentNumber,
            type: 'page',
            current: currentNumber === current
          }
          pageList.push(obj)
        }
      } else {
        // 该出现 ... 了
        if (current < 5) {
          for (let i = 0; i < 5; i += 1) {
            const currentNumber = i + 1
            const obj = {
              showNumber: currentNumber,
              type: 'page',
              current: currentNumber === current
            }
            pageList.push(obj)
          }
          pageList.push(devider)
          pageList.push({
            showNumber: totalPages,
            type: 'page',
            current: totalPages === current
          })
        } else if (totalPages - current < 5) {
          pageList.push({
            showNumber: 1,
            type: 'page',
            current: current === 1
          })
          pageList.push(devider)
          for (let i = totalPages - 4; i <= totalPages; i += 1) {
            const currentNumber = i
            const obj = {
              showNumber: currentNumber,
              type: 'page',
              current: currentNumber === current
            }
            pageList.push(obj)
          }
        } else {
          pageList.push({
            showNumber: 1,
            type: 'page',
            current: current === 1
          })
          pageList.push(devider)

          pageList.push({
            showNumber: current - 1,
            type: 'page',
            current: false
          })

          pageList.push({
            showNumber: current,
            type: 'page',
            current: true
          })

          pageList.push({
            showNumber: current + 1,
            type: 'page',
            current: false
          })

          pageList.push(devider)
          pageList.push({
            showNumber: totalPages,
            type: 'page',
            current: totalPages === current
          })
        }
      }
      this.pageList = pageList
    },
    handleGoTo (item) {
      if (item.type !== 'page') {
        return
      }
      const nextPage = item.showNumber
      // 显示当前的高亮
      this.current = nextPage
      // 重绘组件
      this.init()
      // 调用 onChange
      this.$emit('onChange', nextPage)
    },
    handleJumpTo () {
      if (!this.jumpNextPage) {
        return
      }
      const nextPage = this.jumpNextPage * 1
      this.current = nextPage
      this.init()
      this.$emit('onChange', nextPage)
    },
    handlePageInputBlur () {
      if (!this.jumpNextPage) {
        return
      }
      if (this.jumpNextPage <= 0) {
        this.jumpNextPage = 1
      }
      if (this.jumpNextPage > this.totalPages) {
        this.jumpNextPage = this.totalPages
      }
    },
    handleGoPrev () {
      if (this.current <= 1) {
        return
      }
      this.current = this.current - 1
      this.init()
      this.$emit('onChange', this.current)
    },
    handleGoNext () {
      if (this.current >= this.total) {
        return
      }
      this.current = this.current + 1
      this.init()
      this.$emit('onChange', this.current)
    }
  }
}
