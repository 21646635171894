export default {
  name: 'Alert',
  props: {
    content: {
      type: String,
      default: 'Your message goes here'
    },
    type: {
      type: String,
      default: 'info'
    }
  }
}
