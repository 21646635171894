export default {
  name: 'Radio',
  props: {
    vmodel: String,
    value: String,
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checked: false
    }
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  watch: {
    value (next) {
      this.checked = next === this.name
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.checked = this.value === this.name
    },
    handleSelect () {
      this.$emit('sync', this.name)
    }
  }
}
