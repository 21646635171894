import Button from "./Button";
import Input from "./Input";
import Drawer from "./Drawer";
import Pagination from "./Pagination";
import FormItem from "./FormItem";
import Select from "./Select";
import Cascader from "./Cascader";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import Dialog from "./Dialog";
import Divider from "./Divider";
import Stepper from "./Stepper";
import Alert from "./Alert";
import Toast from "./Toast";
import NumberInput from "./NumberInput";
import Radio from "./Radio";
import FullpageLoading from "./FullpageLoading";
import Popover from "./Popover";
import "animate.css";

const components = [
  Button,
  Input,
  TextArea,
  Drawer,
  Pagination,
  FormItem,
  Select,
  Cascader,
  CheckBox,
  Dialog,
  Divider,
  Stepper,
  Alert,
  Toast,
  NumberInput,
  Radio,
  FullpageLoading,
  Popover,
];
const install = function (Vue) {
  if (install.installed) return;
  components.map((component) => {
    Vue.use(component);
  });
};
//  全局引用可自动安装
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}
export default {
  install,
  ...components,
};
