export default {
  name: 'NumberInput',
  props: {
    min: Number,
    max: Number,
    value: Number
  },
  data () {
    return {
      currentValue: null
    }
  },
  model: {
    props: 'value',
    event: 'sync'
  },
  mounted () {
    this.init()
  },
  watch: {
    currentValue (next) {
      const realNext = parseInt(next) // 强制整数
      this.$emit('sync', realNext)
    }
  },
  methods: {
    init () {
      this.currentValue = this.value
    },
    handleIncrease () {
      this.currentValue += 1
    },
    handleDecrease () {
      this.currentValue -= 1
    },
    handleInputNumber (e) {
      this.currentValue = ~~e.target.value
    },
    handleInputBlur () {
      const next = this.currentValue
      if (this.max && next > this.max) {
        this.currentValue = this.max
      }
      if (this.min && next < this.min) {
        this.currentValue = this.min
      }
    }
  }
}
