import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test"),
  },
  {
    path: "/s/:id",
    name: "SearchPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/SPage"),
  },
  {
    path: "/immersive",
    name: "Immersive",
    component: () =>
      import(/* webpackChunkName: "Immersive" */ "../views/Immersive"),
  },
  {
    path: "/demo",
    name: "Demo",
    component: () => import("../views/Demo"),
  },

  {
    path: "/one-year-test-drive/validate-drive-lisence",
    name: "OneYearTestDrive",
    component: () => import("../views/OneYearTestDrive/validateDriveLisence"),
  },
  {
    path: "/one-year-test-drive",
    component: () =>
      import(
        /* webpackChunkName: "oneYearTestDrive" */ "../views/OneYearTestDrive"
      ),
    children: [
      {
        path: "home",
        component: () => import("../views/OneYearTestDrive/Home"),
        meta: {
          title: "恭喜中奖",
        },
      },
      {
        path: "signup",
        component: () => import("../views/OneYearTestDrive/Signup"),
        meta: {
          title: "填写联系方式",
        },
      },
    ],
  },
  {
    path: "/blind-box-draw",
    name: "blind-box-draw",
    component: () =>
      import(/* webpackChunkName: "blindBoxDraw" */ "../views/BlindBoxDraw"),
  },
  {
    path: "/blind-box-head",
    name: "blind-box-head",
    component: () =>
      import(/* webpackChunkName: "blindBoxHead" */ "../views/BlindBoxHead"),
  },
  {
    path: "/app2web",
    name: "app2web",
    component: () =>
      import(/* webpackChunkName: "appToWeb" */ "../views/AppToWeb"),
  },
  // {
  //   path: '/drive',
  //   name: 'Drive',
  //   component: () => import('../views/Drive')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
