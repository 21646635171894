import FullpageLoading from "./component";

/* istanbul ignore next */
FullpageLoading.install = function (Vue) {
  // 生成一个 Vue 子类
  const FullpageLoadingConstructor = Vue.extend(FullpageLoading);
  // 生成一个该子类的实例
  const instance = new FullpageLoadingConstructor();

  // 将实例挂载
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  // 通过Vue的原型注册一个方法
  // 让所有实例共享这个方法
  Vue.prototype.$FullPageLoading = (option) => {
    instance.visible = true;
  };
  Vue.prototype.$ExitFullPageLoading = (option) => {
    instance.visible = false;
  };
};

export default FullpageLoading;
