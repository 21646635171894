export default {
  name: 'Button',
  props: {
    type: {
      required: false,
      default: 'default'
    },
    theme: {
      required: false,
      default: 'dark'
    },
    disabled: Boolean,
    plain: Boolean,
    flat: Boolean,
    circle: Boolean,
    loading: Boolean,
    size: {
      type: String,
      default: 'large'
    }
  },
  data () {
    return {
      btnClass: ['dv-button']
    }
  },
  mounted () {
    this.initStyle()
  },
  methods: {
    initStyle () {
      !this.plain && !this.flat && this.btnClass.push('dv-button-default')
      this.theme && this.theme === 'reverse' && this.btnClass.push('dv-button-reverse')
      this.plain && this.btnClass.push('dv-button-plain')
      this.flat && this.btnClass.push('dv-button-flat')
      this.btnClass.push(`dv-button-${this.size}`)
    },
    onClick (evt) {
      this.$emit('click', evt)
    }
  }
  // `dv-button ${type && 'dv-button-' + type} ${plain && 'dv-button-plain'}`
}
