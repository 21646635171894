export default {
  name: 'Drawer',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {
      css: {
        cover: '',
        container: ''
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.css = {
        cover: 'animate__fadeIn',
        container: 'animate__fadeInRightBig'
      }
    },
    handleCloseDrawer () {
      this.css = {
        cover: 'animate__fadeOut',
        container: 'animate__fadeOutRightBig'
      }
      setTimeout(() => {
        this.$emit('update:visible', false)
        this.css = {
          cover: 'animate__fadeIn',
          container: 'animate__fadeInRightBig'
        }
      }, 300)
    }
  }
}
