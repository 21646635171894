export default {
  name: 'CheckBox',
  props: {
    vmodel: Boolean,
    value: Boolean
  },
  data () {
    return {
      checked: false
    }
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  watch: {
    checked (next) {
      this.$emit('sync', next)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.checked = this.value
    },
    handleSelect () {
      this.checked = !this.checked
    }
  }
}
