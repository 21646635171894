export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Array,
      default: ['Step 1', 'Step 2', 'Step 3', 'Step 4']
    },
    current: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      height: '50px'
    }
  }
}
