import Toast from "./component/index.vue";

/* istanbul ignore next */
Toast.install = function (Vue) {
  // 生成一个 Vue 子类
  const ToastConstructor = Vue.extend(Toast);
  // 生成一个该子类的实例
  const instance = new ToastConstructor();

  // 将实例挂载
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  // 通过Vue的原型注册一个方法
  // 让所有实例共享这个方法
  Vue.prototype.$Toast = function (props) {
    props = Object.assign({ isShow: true }, props);

    for (const key in props) {
      instance[key] = props[key];
    }

    if (props.type !== "loading") {
      setTimeout(() => {
        instance.isShow = false;
        instance.content = "";
        instance.title = "";
        instance.type = "";
      }, 1500);
    }
  };
};

export default Toast;
