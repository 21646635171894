export default {
  name: 'Cascader',
  props: {
    placeholder: String,
    vmodel: [String, Number],
    options: Array,
    value: Array
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  data () {
    return {
      focus: false,
      showList: false,
      selected: [],
      Level2Arr: [],
      selectedIndexLevel1: -1,
      selectedIndex: -1, // 选中的 index
      optionList: []
    }
  },
  watch: {
    value (val) {
      this.watchValue(val)
    },
    options (next) {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 初始化)
      this.optionList = this.options
      this.optionList.map((e, index) => {
        if (this.value && e.value === this.value[0]) {
          e.current = true
          this.selected[0] = e
          this.selectedIndexLevel1 = index
          e.children && (() => {
            this.Level2Arr = e.children
            e.children.map((c, cIndex) => {
              if (c.value === this.value[1]) {
                c.current = true
                this.selected[1] = c
                this.selectedIndex = cIndex
              }
            })
          })()
        }
      })
      this.$forceUpdate()
    },
    watchValue (val) {
      if (val) {
        this.init()
      } else {
        this.selected = []
        this.selectedIndexLevel1 = -1
        this.selectedIndex = -1
      }
    },
    handleShowList () {
      this.focus = true
      this.showList = true
      setTimeout(() => {
        document.addEventListener('click', this.handleHideOptionList)
      }, 500)
    },
    handleSelectLevel1 (item, index) {
      this.Level2Arr = item.children
      this.optionList.map(e => {
        e.current = false
      })
      this.optionList[index].current = true
      this.selected[0] = JSON.parse(JSON.stringify(item))
      this.selected.splice(1, 1)
    },
    handleSelect (item, index) {
      if (item.disabled) return
      this.selected[1] = JSON.parse(JSON.stringify(item))
      this.selectedIndex = index
      this.optionList.map(e => {
        e.current = false
        e.children && e.children.map((c) => {
          c.current = false
        })
      })
      this.Level2Arr[index].current = true
      const ret = []
      this.selected.map(e => {
        ret.push(e.value)
      })
      this.$forceUpdate()
      this.handleHideOptionList()
      this.$emit('sync', ret)
    },
    handleShowListToggle () {
      if (this.showList) {
        this.handleHideOptionList()
      } else {
        this.handleShowList()
      }
    },
    handleHideOptionList (e) {
      this.hideOptionList()
      document.removeEventListener('click', this.handleHideOptionList)
      if (e) {
        this.$nextTick(() => {
          this.watchValue(this.value)
        })
      }
    },
    hideOptionList () {
      // 关闭下拉菜单，用来写动画的
      this.showList = false
    }
  }
}
