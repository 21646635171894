export default {
  name: 'Dialog',
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: 'Dialog'
    },
    content: {
      type: String,
      default: 'Message text goes here'
    },
    showOkBtn: Boolean,
    showCancelBtn: Boolean,
    okBtnText: {
      type: String,
      default: 'ok'
    },
    cancelBtnText: {
      type: String,
      default: 'cancel'
    },
    // icon关闭弹框是否调用ok
    isOkCancel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleOkClicked () {
      this.$emit('onOk')
    },
    handleCancelClicked (type) {
      this.$emit(this.isOkCancel && type === 'icon' ? 'onOk' : 'onCancel')
    }
  }
}
