export default {
  name: 'Divider',
  props: {
    contentPosition: {
      type: String,
      default: 'center',
      validator (val) {
        return ['left', 'center', 'right'].indexOf(val) !== -1
      }
    }
  }
}
