export default {
  name: 'Select',
  props: {
    placeholder: String,
    vmodel: [String, Number],
    options: Array,
    value: [String, Number],
    filterable: {
      type: Boolean,
      Default: false
    }
  },
  model: {
    prop: 'value',
    event: 'sync'
  },
  data () {
    return {
      focus: false,
      showList: false,
      selected: null,
      selectedIndex: -1, // 选中的 index
      filterKeyword: '', // 筛选器文字
      optionList: []
    }
  },
  watch: {
    value (val) {
      this.watchValue(val)
    },
    filterKeyword (next) {
      this.updateOptionList(next)
    },
    options () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 初始化
      this.options.map((e, index) => {
        if (e.value === this.value) {
          this.selected = e
          this.selectedIndex = index
          this.filterKeyword = e.label
        }
      })
    },
    watchValue (val) {
      if (val) {
        this.init()
      } else {
        this.selected = null
        this.selectedIndex = -1
        this.filterKeyword = ''
      }
    },
    handleShowList () {
      this.focus = true
      !this.keyword && this.updateOptionList()
      this.showList = true
      setTimeout(() => {
        document.addEventListener('click', this.handleHideOptionList)
      }, 500)
    },
    handleSelect (item, index) {
      if (item.disabled) return
      this.selected = item
      this.selectedIndex = index
      this.filterKeyword = item.label
      this.options.map(e => {
        e.current = false
      })
      this.options[index].current = true
      this.handleHideOptionList()
      this.$emit('sync', item.value)
    },
    handleShowListToggle () {
      if (this.showList) {
        this.handleHideOptionList()
      } else {
        this.handleShowList()
      }
    },
    handleHideOptionList (e) {
      this.hideOptionList()
      document.removeEventListener('click', this.handleHideOptionList)
      if (e) {
        this.$nextTick(() => {
          this.watchValue(this.value)
        })
      }
    },
    hideOptionList () {
      // 关闭下拉菜单，用来写动画的
      this.showList = false
    },
    updateOptionList (keyword) {
      if (!keyword) {
        this.optionList = this.options
        this.selected = null
        return
      }
      const nextList = []
      this.options.map(e => {
        const label = e.label.toLowerCase()
        keyword = keyword.toLowerCase()
        if (label.indexOf(keyword) !== -1) {
          nextList.push(e)
        }
      })
      this.optionList = nextList
    }
  }
}
